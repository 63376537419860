import { combineReducers } from 'redux';
import appointmentTypes from './appointmentTypesReducer';
import breadcrumb from './breadcrumbReducer';
import healthSystem from './healthSystemReducer';
import educationalPage from './educationalPageReducer';
import geocoding from './geocodingReducer';
import providers from './providersReducer';
import insurancePlans from './insurancePlansReducers';
import services from './servicesReducer';
import serviceLines from './serviceLinesReducer';
import authToken from './authTokenReducers';
import apiCallsInProgress, { apiAuthSuccess } from './apiStatusReducer';
import searchParams from './searchParamsReducer';
import searchFilters from './searchFiltersReducer';
import searchResult from './searchResultReducer';
import ui from './uiReducer';
import venuesOfCare from './venuesOfCareReducer';
import visitSettings from './visitSettingsReducer';
import scheduleReducer from './scheduleReducer';
import facilitiesReducer from './facilitiesReducer';
import visitReducer from './visitReducer';
import scheduleDetailsReducer from './scheduleDetailsReducer';
import settingsReducer from './settingsReducer';
import reschedule from './rescheduleReducer';

const rootReducer = combineReducers({
  appointmentTypes,
  authToken,
  apiAuthSuccess,
  apiCallsInProgress,
  breadcrumb,
  educationalPage,
  geocoding,
  healthSystem,
  insurancePlans,
  providers,
  services,
  serviceLines,
  searchParams,
  venuesOfCare,
  searchFilters,
  searchResult,
  ui,
  visitSettings,
  scheduleReducer,
  facilitiesReducer,
  visitReducer,
  scheduleDetailsReducer,
  settingsReducer,
  reschedule
});

export default rootReducer;
