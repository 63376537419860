/* eslint-disable no-case-declarations */
import * as types from '../actions/actionTypes';
import initialState from './initialState';

const extractZipcode = apiData => {
  /* Geo Coding API return plus_code for some areas */
  let inputType = '';
  let inputLength = 0;
  let zipCode = '';
  let state = '';
  if (
    localStorage.getItem('zipCode') !== null &&
    localStorage.getItem('zipCode') !== 'undefined' &&
    localStorage.getItem('zipCode') !== ''
  ) {
    if (localStorage.getItem('zipCode').indexOf(',') > -1) {
      const zipCodesArr = localStorage.getItem('zipCode').split(',');
      if (
        zipCodesArr[1].indexOf(' ') > -1 &&
        zipCodesArr[1].split(' ').length > 2
      ) {
        zipCode = zipCodesArr[1].split(' ')[2];
      } else {
        zipCode = zipCodesArr[0];
        state = zipCodesArr[1];
      }
    } else zipCode = localStorage.getItem('zipCode');
    inputType = isNaN(Number(zipCode));
    inputLength = zipCode.length;
  }
  if (inputType !== '' && inputLength > 0) {
    if (!inputType && inputLength > 4) {
      if (
        apiData.results[0].address_components.filter(
          a => a.types.indexOf('postal_code') >= 0,
        ).length > 0
      ) {
        return apiData.results[0].address_components.filter(
          a => a.types.indexOf('postal_code') >= 0,
        )[0].long_name;
      }
    }
    if (inputType && inputLength > 4) {
      if (
        apiData.results[0].address_components.filter(
          a => a.types.indexOf('locality') >= 0,
        ).length > 0
      ) {
        return `${apiData.results[0].address_components
          .filter(a => a.types.indexOf('locality') >= 0)[0]
          .long_name.trim()},${state.trim()}`;
      }
    }
  }

  // eslint-disable-next-line no-nested-ternary
  return localStorage.getItem('zipCode') === 'undefined' ||
    localStorage.getItem('zipCode') === '' ||
    localStorage.getItem('zipCode') === null ||
    localStorage.getItem('zipCode') === undefined
    ? apiData?.results?.[0]?.address_components.filter(
      a => a.types.indexOf('postal_code') >= 0,
    ).length > 0
      ? apiData.results[0].address_components.filter(
        a => a.types.indexOf('postal_code') >= 0,
      )[0].long_name
      : ''
    : localStorage.getItem('zipCode');
};

const geocodingData = apiData => {
  if (!apiData.results.length) return {};
  if (apiData.results.length > -1) {
    if (
      apiData.results[0].address_components.filter(
        a => a.types.indexOf('locality') >= 0,
      ).length > 0 ||
      apiData.results[0].address_components.filter(
        a => a.types.indexOf('postal_code') >= 0,
      ).length > 0 ||
      apiData.results[0].address_components.filter(
        a => a.types.indexOf('administrative_area_level_3') >= 0,
      ).length > 0
    ) {
      if (localStorage.getItem('zipCode') === '') {
        localStorage.setItem('zipCode', extractZipcode(apiData));
      }
      return {
        zipcode: extractZipcode(apiData),
        coords: apiData.results[0].geometry.location,
      };
    }

    if (
      apiData.results[0].address_components.filter(
        a => a.types.indexOf('country') >= 0,
      ).length > 0
    ) {
      return {
        zipcode: '',
        coords: apiData.results[0].geometry.location,
      };
    }
  }
};

export default function geocodingReducer(
  state = initialState.geocoding,
  action,
) {
  switch (action.type) {
    case types.CLEAR_GEOCODING:
      const emptyGeocoding = {
        zipcode: action.zipCode,
        coords: { lat: 0.0, lng: 0.0 },
      };
      return {
        ...state,
        ...emptyGeocoding,
        ziploaded: true,
        zipcodeValid: true,
      };
    case types.FETCH_GEOCODING_SUCCESS:
      const { geocodingApiData } = action;
      const { status } = geocodingApiData;
      const geocoding = geocodingData(geocodingApiData);
      return {
        ...state,
        ...geocoding,
        loaded: true,
        zipcodeValid: status === 'OK',
      };
    case types.FETCH_REVERSE_GEOCODING_SUCCESS:
      const { reverseGeocodingApiData } = action;
      const reverseGeocoding = geocodingData(reverseGeocodingApiData);
      return {
        ...state,
        ...reverseGeocoding,
        loaded: true,
        zipcodeValid: true,
      };
    case types.BACK_TO_LANDING_PAGE:
      return initialState.geocoding;
    default:
      return state;
  }
}
