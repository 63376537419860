export default [
  {
    page: 'Health System Landing Page',
    canonical: 'https://[[hs-permalink]]',
    title: 'Schedule a Healthcare Appointment Online | [[hs-name]]',
    description:
      '[[hs-name]] Online Scheduling - Find [[hs-name]] medical providers and services and schedule your appointment online. Self-service online scheduling is fast, easy and free with [[hs-name]].',
    type: 'HealthSystem',
    pageType: 'LandingPage',
  },
  {
    page:
      'Health system Service Line Pages (ED, UC and Walk-in, Immediate Care)',
    canonical: 'https://[[hs-permalink]]/[[service-line-permalink]]-services',
    title: '[[service-name]] Near Me | Hold My Spot | [[hs-name]]',
    description:
      "[[service-name]] Near Me - Find [[service-name]] locations near you by [[hs-name]] and hold your [[service-name]] spot in line by checking in online. Skip the lobby and wait in the comfort of your own home when you reserve your [[hs-name]] [[service-name]] spot online. It's fast, easy and free!",
    type: 'HealthSystem',
    pageType: 'ServiceLinePageEC',
  },
  {
    page:
      'Health system Service Line Pages (non-ED, UC and Walk-in, Immediate Care)',
    canonical: 'https://[[hs-permalink]]/[[service-line-permalink]]-services',
    title: '[[service-name]] Near Me | Schedule Online | [[hs-name]]',
    description:
      "[[service-name]] Near Me - Find [[service-name]] providers and/or locations near you by [[hs-name]] and schedule your [[service-name]] appointment online. Don't wait on hold on the phone, instead save yourself time by booking [[service-name]] appointments online with [[hs-name]]. It's fast, easy and free!",
    type: 'HealthSystem',
    pageType: 'ServiceLinePageNonEC',
  },
  {
    page: 'Health system Find a Doc',
    canonical: 'https://[[hs-permalink]]/search',
    title: 'Doctors Near Me | Schedule Online | [[hs-name]]',
    description:
      'Doctors Near Me - Find local [[hs-name]] Doctors, Nurse Practitioners, Physician Assistants and more near you and schedule an online appointment. Easily search all [[hs-name]] providers who offer online scheduling near you and quickly book an appointment today.',
    type: 'HealthSystem',
    pageType: 'FindDoc',
  },
  {
    page: 'Health System Facility Pages',
    canonical: 'https://[[hs-permalink]]/facility/[[facility-permalink]]',
    title: '[[facility-name]] | View Open Times | [[hs-name]]',
    description:
      '[[facility-name]] is a healthcare facility associated with [[hs-name]].  View online avialability for all doctors and other medical providers at [[facility-name]] and schedule yourself in for an in-person visit online.  Online self-scheduling is quick, easy and free!',
    type: 'HealthSystem',
    pageType: 'FacilityPage',
  },
  {
    page: 'Health System Location Pages',
    canonical:
      'https://[[hs-permalink]]/facility/[[facility-permalink]]/[[location-permalink]]',
    title:
      '[[facility-name]] | [[location-city]], [[location-state]] [[location-zip]]',
    description:
      '[[location-name]] located in [[location-city]], [[location-state]] offers [[service-name]] services to patients in the [[location-zip]] zip code area.  Search the online availability of all providers who practice at [[location-name]] and easily book an appointment with one of those providers today.',
    type: 'HealthSystem',
    pageType: 'LocationPage',
  },
  {
    page: 'Health System Provider Pages',
    canonical: 'https://[[hs-permalink]]/provider/[[provider-permalink]]',
    title:
      '[[provider-name]], [[provider-suffix]] | [[service-name]] | [[location-city]], [[location-state]] [[location-zip]]',
    description:
      '[[provider-name]], [[provider-suffix]] Online Scheduling - Accepting new patients, [[provider-name]], [[provider-suffix]] is a board certified [[service-name]] provider affiliated with [[hs-name]] in the [[location-city]], [[location-state]].  Get the best care and book an appointment online with [[provider-name]], [[provider-suffix]] today. Self-scheduling is quick, easy and free!',
    type: 'HealthSystem',
    pageType: 'ProviderPage',
  },
  {
    page: 'Health System Full Schedule Pages',
    canonical: 'https://[[hs-permalink]]/schedule/calendar/[[schedule-id]]',
    title:
      'View Online Availability | [[provider-name]], [[provider-suffix]] | [[service-name]]',
    description:
      "View Online Availability For [[provider-name]], [[provider-suffix]] - Search [[provider-name]], [[provider-suffix]]'s available time slots for [[service-name]] openings near you and schedule your in-person online appointment today. Plan your [[service-name]] appointment with [[provider-name]], [[provider-suffix]] with ease on your time, anywhere, anytime.",
    type: 'HealthSystem',
    pageType: 'FullSchedulePage',
  },
  {
    page: 'Health System Sitemap Page',
    canonical: 'https://https://[hs-permalink]]/sitemap',
    title: 'InQuicker HTML Sitemap | [[hs-name]]',
    description: 'HTML Sitemap of [[hs-name]] on InQuicker',
    type: 'HealthSystem',
    pageType: 'Sitemap',
  },
  {
    page: 'Health System Sitemap: Cities',
    canonical: 'https://[[hs-permalink]]/sitemap/cities',
    title: 'InQuicker HTML Sitemap| Cities | [[hs-name]]',
    description: 'HTML Sitemap of [[hs-name]] Cities on InQuicker',
    type: 'HealthSystem',
    pageType: 'SitemmapCities',
  },
  {
    page: 'Health System Sitemap: Services',
    canonical: 'https://[[hs-permalink]]/sitemap/services',
    title: 'InQuicker HTML Sitemap | Services | [[hs-name]]',
    description: 'HTML Sitemap of [[hs-name]] Services on InQuicker',
    type: 'HealthSystem',
    pageType: 'SitemapServices',
  },
  {
    page: 'Health System Sitemap: Facilities',
    canonical: 'https://[[hs-permalink]]/sitemap/facilities',
    title: 'InQuicker HTML Sitemap | Facilities | [[hs-name]]',
    description: 'HTML Sitemap of [[hs-name]] Facilities on InQuicker',
    type: 'HealthSystem',
    pageType: 'SitemapFacilities',
  },
  {
    page: 'Health System Sitemap: Providers',
    canonical: 'https://[[hs-permalink]]/sitemap/providers',
    title: 'InQuicker HTML Sitemap | Providers | [[hs-name]]',
    description: 'HTML Sitemap of [[hs-name]] Providers on InQuicker',
    type: 'HealthSystem',
    pageType: 'SitemapProviders',
  },
  {
    page: 'Health System /service/state/city pages',
    canonical: 'https://[[hs-permalink]]/search',
    title:
      '[[service-name]] Scheduling | [[location-city]], [[location-state]] | [[hs-name]]',
    description:
      'Find and scheudle [[service-name]] health care in [[location-city]], [[location-state]] by [[hs-name]].  Quickly search all [[hs-name]] [[service-name]] appointments currently available in [[location-city]], [[location-state]] and easily schedule your [[hs-name]] [[service-name]] in [[location-city]], [[location-state]] today.',
    type: 'HealthSystem',
    pageType: 'CityStatePages',
  },
  {
    page: 'Health System Patient Intake pages',
    canonical: 'https://[[hs-permalink]]/patient-intake/[[secure-id]]',
    title: 'Intake for Upcoming Appointment | [[hs-name]]',
    description: 'HTML Patient Intake of [[hs-name]] on InQuicker',
    type: 'HealthSystem',
    pageType: 'PatientIntakePages',
  },
  {
    page: 'Region Landing Page',
    canonical: 'https://[[hs-permalink]]',
    title: 'Schedule a Healthcare Appointment Online | [[region-name]]',
    description:
      '[[region-name]] Online Scheduling - Find [[region-name]] medical providers and services and schedule your appointment online. Self-service online scheduling is fast, easy and free with [[region-name]].',
    type: 'Region',
    pageType: 'LandingPage',
  },
  {
    page: 'Region Service Line Pages (ED, UC and Walk-in, Immediate Care)',
    canonical: 'https://[[hs-permalink]]/[[service-line-permalink]]-services',
    title: '[[service-name]] Near Me | Hold My Spot | [[region-name]]',
    description:
      "[[service-name]] Near Me - Find [[service-name]] locations near you by [[region-name]] and hold your [[service-name]] spot in line by checking in online. Skip the lobby and wait in the comfort of your own home when you reserve your [[region-name]] [[service-name]] spot online. It's fast, easy and free!",
    type: 'Region',
    pageType: 'ServiceLinePageEC',
  },
  {
    page: 'Region Service Line Pages (non-ED, UC and Walk-in, Immediate Care)',
    canonical: 'https://[[hs-permalink]]/[[service-line-permalink]]-services',
    title: '[[service-name]] Near Me | Schedule Online | [[region-name]]',
    description:
      "[[service-name]] Near Me - Find [[service-name]] providers and/or locations near you by [[region-name]] and schedule your [[service-name]] appointment online. Don't wait on hold on the phone, instead save yourself time by booking [[service-name]] appointments online with [[region-name]]. It's fast, easy and free!",
    type: 'Region',
    pageType: 'ServiceLinePageNonEC',
  },
  {
    page: 'Region Find a Doc',
    canonical: 'https://[[hs-permalink]]/search',
    title: 'Doctors Near Me | Schedule Online | [[region-name]]',
    description:
      'Doctors Near Me - Find local [[region-name]] Doctors, Nurse Practitioners, Physician Assistants and more near you and schedule an online appointment. Easily search all [[region-name]] providers who offer online scheduling near you and quickly book an appointment today.',
    type: 'Region',
    pageType: 'FindDoc',
  },
  {
    page: 'Region Facility Pages',
    canonical: 'https://[[hs-permalink]]/facility/[[facility-permalink]]',
    title: '[[facility-name]] | View Open Times | [[region-name]]',
    description:
      '[[facility-name]] is a healthcare facility associated with [[region-name]]. View online avialability for all doctors and other medical providers at [[facility-name]] and schedule yourself in for an in-person visit online. Online self-scheduling is quick, easy and free!',
    type: 'Region',
    pageType: 'FacilityPage',
  },
  {
    page: 'Region Location Pages',
    canonical:
      'https://[[hs-permalink]]/facility/[[facility-permalink]]/[[location-permalink]]',
    title:
      '[[facility-name]] | [[location-city]], [[location-state]] [[location-zip]]',
    description:
      '[[location-name]] located in [[location-city]], [[location-state]] offers [[service-name]] services to patients in the [[location-zip]] zip code area.  Search the online availability of all providers who practice at [[location-name]] and easily book an appointment with one of those providers today.',
    type: 'Region',
    pageType: 'LocationPage',
  },
  {
    page: 'Region Provider Pages',
    canonical: 'https://[[hs-permalink]]/provider/[[provider-permalink]]',
    title:
      '[[provider-name]], [[provider-suffix]] | [[service-name]] | [[location-city]], [[location-state]] [[location-zip]]',
    description:
      '[[provider-name]], [[provider-suffix]] Online Scheduling - Accepting new patients, [[provider-name]], [[provider-suffix]] is a board certified [[service-name]] provider affiliated with [[region-name]] in the [[location-city]], [[location-state]].  Get the best care and book an appointment online with [[provider-name]], [[provider-suffix]] today. Self-scheduling is quick, easy and free!',
    type: 'Region',
    pageType: 'ProviderPage',
  },
  {
    page: 'Region Full Schedule Pages',
    canonical: 'https://[[hs-permalink]]/schedule/calendar/[[schedule-id]]',
    title:
      'View Online Availability | [[provider-name]], [[provider-suffix]] | [[service-name]]',
    description:
      "View Online Availability For [[provider-name]], [[provider-suffix]] - Search [[provider-name]], [[provider-suffix]]'s available time slots for [[service-name]] openings near you and schedule your in-person online appointment today. Plan your [[service-name]] appointment with [[provider-name]], [[provider-suffix]] with ease on your time, anywhere, anytime.",
    type: 'Region',
    pageType: 'FullSchedulePage',
  },
  {
    page: 'Region Sitemap Page',
    canonical: 'https://https://[hs-permalink]]/sitemap',
    title: 'InQuicker HTML Sitemap | [[region-name]]',
    description: 'HTML Sitemap of [[region-name]] on InQuicker',
    type: 'Region',
    pageType: 'Sitemap',
  },
  {
    page: 'Region Sitemap: Cities',
    canonical: 'https://[[hs-permalink]]/sitemap/cities',
    title: 'InQuicker HTML Sitemap| Cities | [[region-name]]',
    description: 'HTML Sitemap of [[region-name]] Cities on InQuicker',
    type: 'Region',
    pageType: 'SitemmapCities',
  },
  {
    page: 'Region Sitemap: Services',
    canonical: 'https://[[hs-permalink]]/sitemap/services',
    title: 'InQuicker HTML Sitemap | Services | [[region-name]]',
    description: 'HTML Sitemap of [[region-name]] Services on InQuicker',
    type: 'Region',
    pageType: 'SitemapServices',
  },
  {
    page: 'Region Sitemap: Facilities',
    canonical: 'https://[[hs-permalink]]/sitemap/facilities',
    title: 'InQuicker HTML Sitemap | Facilities | [[region-name]]',
    description: 'HTML Sitemap of [[region-name]] Facilities on InQuicker',
    type: 'Region',
    pageType: 'SitemapFacilities',
  },
  {
    page: 'Region Sitemap: Providers',
    canonical: 'https://[[hs-permalink]]/sitemap/providers',
    title: 'InQuicker HTML Sitemap | Providers | [[region-name]]',
    description: 'HTML Sitemap of [[region-name]] Providers on InQuicker',
    type: 'Region',
    pageType: 'SitemapProviders',
  },
  {
    page: 'Region /service/state/city pages',
    canonical: 'https://[[hs-permalink]]/search',
    title:
      '[[service-name]] Scheduling | [[location-city]], [[location-state]] | [[region-name]]',
    description:
      'Find and scheudle [[service-name]] health care in [[location-city]], [[location-state]] by [[region-name]].  Quickly search all [[region-name]] [[service-name]] appointments currently available in [[location-city]], [[location-state]] and easily schedule your [[region-name]] [[service-name]] in [[location-city]], [[location-state]] today.',
    type: 'Region',
    pageType: 'CityStatePages',
  },
  {
    page: 'Region Patient Intake pages',
    canonical: 'https://[[hs-permalink]]/patient-intake/[[secure-id]]',
    title: 'Intake for Upcoming Appointment | [[region-name]]',
    description: 'HTML Patient Intake of [[region-name]] on InQuicker',
    type: 'Region',
    pageType: 'PatientIntakePages',
  },
];
