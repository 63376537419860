import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function rescheduleReducer(state = initialState.reschedule, action) {
    switch (action.type) {
        case types.IS_RESCHEDULING:
            return {
                ...state,
                isreschedule: action.payload.isreschedule,
                visitId: action.payload.visitId,
                secureId: action.payload.secureId
            }
        default:
            return state;
    }

}
