import React, { useEffect, useState, useContext } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty, isNull, isUndefined, groupBy } from 'lodash';
import { Grid, Typography, Badge } from '@material-ui/core';
import Button from 'react-bootstrap/Button';
import { getVisit, cancel } from 'api/visitApi';
import PageNotFound from 'components/PageNotFound';
import CustomSelect from 'components/form/CustomSelect';
import { displayNotification } from 'redux/actions/uiActions';
import { BrandingContext } from 'components/contexts/BrandingContext';
import { makeStyles } from '@material-ui/core/styles';
import BreadCrumbs from '../common/BreadCrumbs';
import { getCurrency } from '../../utils/helpers';
import { setProviderAvatar } from '../../helpers/selectProviderAvatar';
import { isMobile } from 'react-device-detect';
import Location from 'components/common/Location';
import Phone from 'components/common/Phone';
import './VisitCancelPage.scss';
import { useTranslation, Trans } from 'react-i18next';
import camel from 'camelcase-keys';
import moment from 'moment-timezone';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DateCarousel from 'components/appointmentPicker/DateCarousel';
import { ER_SERVICE_NAMES, UC_SERVICE_NAMES } from 'config/searchConstants';
import { groupedDays } from 'utils/dateTimeUtils';
import Sticky from 'react-sticky-el';
import FullSchedule from 'components/common/FullSchedule';
import FacilityDateCarousel from 'components/appointmentPicker/FacilityDateCarousel';
import { Link } from 'react-router-dom';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DialogMobile from 'components/dialogs/DialogMobile';
import PickerDate from 'components/dialogs/DatePicker';
import { WatchLater } from '@material-ui/icons';
import useSettings from 'hooks/useSettings';
import { getTimes } from 'api/availableTimesApi';
import QueryString from 'query-string';
import Appointment from 'components/appointmentPicker/Appointment';
import { isServiceGroupB_C } from 'config/serviceLineGroupMappings';
import AvailableTimesToday from 'components/appointmentPicker/AvailableTimesToday';
import { setReschedulingPage } from 'redux/actions/rescheduleActions';

const VisitCancelPage = ({ match }) => {
  const history = useHistory();
  const [visit, setVisit] = useState({
    data: null,
  });

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.only('md'));
  const isLgDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [numberOfDays, setNumberOfDays] = useState();
  const [date, setDate] = useState();
  const [dates, setDates] = useState([]);
  const [whoIsScrolling, setWhoIsScrolling] = useState();
  const [scrollLeft, setScrollLeft] = useState({
    left: '0',
    fromComponent: 'first',
  });

  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [showTimePickerDialog, setShowTimePickerDialog] = useState(false);
  const [dateTemp, setDateTemp] = useState({});
  const [facility, setFacility] = useState(null);
  const [location, setLocation] = useState({});
  const [provider, setProvider] = useState({});
  const [schedule, setSchedule] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({});
  const [scheduleId, setScheduleId] = useState("");
  const [pastDate, setPastDate] = useState({});
  const [isCancelledVisit, setVisitCancelled] = useState(false);
  const [isReschedulePage, setReschedulePage] = useState(false);
  const [cancelForm, setCancelForm] = useState({
    reason: {
      value: '',
    },
  });
  const [todaySchedule, setTodaySchedule] = useState({ day: '', times: [] });
  const [nextAvailableTime, setNextAvailableTime] = useState('');
  const [loading, setLoading] = useState(false);
  const { branding } = useContext(BrandingContext);
  const healthSystem = useSelector(state => state.healthSystem);
  const [noAvailableAppointments, setNoAvailableAppointments] = useState();
  const [hideFullSchedule, setHideFullSchedule] = useState(false);

  const allServices = useSelector(state => state?.serviceLines?.services);
  const allServiceLines = useSelector(state => state?.serviceLines?.serviceLines);
  const dispatch = useDispatch();

  const useStyles = makeStyles({
    button: {
      marginLeft: "12px",
      padding: "15px",
      borderRadius: 2,
      backgroundColor: branding.buttonColor,
      borderColor: branding.buttonColor,
      color: branding.buttonTextColor,
      fontWeight: "600",
      '&:hover': {
        color: branding.buttonColor,
        borderColor: branding.buttonColor,
        backgroundColor: branding.buttonTextColor,
      },
      '&:active': {
        color: `${branding.buttonColor} !important`,
        borderColor: `${branding.buttonColor} !important`,
        backgroundColor: `${branding.buttonTextColor} !important`,
      },
      '&:focus': {
        color: `${branding.buttonColor} !important`,
        borderColor: `${branding.buttonColor} !important`,
        backgroundColor: `${branding.buttonTextColor} !important`,
      },
    },
    separator: {
      width: "95% !important",
      backgroundColor: "#c3c3c3",
    },
    separatorVertical: {
      borderLeft: "1px solid #c3c3c3",
      paddingInline: "10px"
    },
    rescheduleAppt: {
      fontWeight: "bold",
      fontSize: "17px",
      marginLeft: "-3px"
    },
    errorWrapper: {
      color: "#cc0000",
      paddingBottom: "10px",
      marginTop: "8px !important"
    },
    mapButton: {
      background: 'transparent',
      border: 'none',
      appearance: 'none',
    },
    cancelAppt: {
      fontWeight: "bold",
      fontSize: "15px",
      paddingBottom: "5px"
    },
    fullSchedule: {
      marginLeft: "24px",
      marginBottom: "5px"
    }

  });
  const classes = useStyles();
  const [t] = useTranslation();

  const availableScheduleDays = useSettings({
    facilityId: facility,
    caller: 'AvailableTimesToday',
  });

  useEffect(() => {
    if (t('noAvailableAppointments') !== 'noAvailableAppointments') {
      const key = t('noAvailableAppointments');
      setNoAvailableAppointments(key.replace('90', availableScheduleDays));
    }
  }, [availableScheduleDays, t('noAvailableAppointments')]);

  useEffect(() => {
    const service = schedule.service?.permalink;
    if (isServiceGroupB_C(service)) {
      setNoAvailableAppointments(t('noAvailableAppointmentsGroupBC'));
    }
  }, [schedule]);

  useEffect(() => {
    (async () => {
      if (visit?.data === null) {
        setReschedulePage(window.location.href.includes('reschedule'))
        const v = await getVisit(match.params.visitId, {
          include: 'location,provider,schedule,schedule.facility',
        });
        if (v?.data !== null) {

          setVisit(v);
          if (v?.data?.attributes['payment-info'] !== null) {
            const paymentInfo = JSON.parse(v?.data?.attributes['payment-info']);
            setPaymentInfo(paymentInfo);
          }

          const loc = v?.included?.find(l => l?.type === 'locations');
          setLocation(loc?.attributes);
          const prov = v?.included?.find(l => l?.type === 'providers');
          setProvider(prov);
          const schedule = v?.included?.find(l => l?.type === 'schedules');
          setSchedule(schedule?.attributes);
          setScheduleId(schedule?.id);
          const facility = v?.included?.find(l => l?.type === 'facilities');
          setFacility(facility?.attributes);

          let ApptDate = moment(v?.data?.attributes['appointment-at'])
          let now = moment();
          setPastDate(now.isAfter(ApptDate))
          let visitStatus = v?.data?.attributes['status']
          if (visitStatus == 'canceled' || visitStatus == 'patient-canceled') {
            setVisitCancelled(true);
            let rescheduleParams = { isreschedule: false, secureId: match.params.visitId, visitId: v?.data?.id };
            dispatch(setReschedulingPage(rescheduleParams));
          } else {
            let rescheduleParams = { isreschedule: true, secureId: match.params.visitId, visitId: v?.data?.id }
            dispatch(setReschedulingPage(rescheduleParams));
          }

          setDate(
            moment()
              .tz(healthSystem['time-zone'])
              .format(),
          );
        } else {
          setVisit({ data: 'loaded' });
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (isTablet) {
      setNumberOfDays(3);
    }

    if (isDesktop) {
      setNumberOfDays(5);
    }

    if (isLgDesktop) {
      setNumberOfDays(5);
    }

    if (isMobile) {
      setNumberOfDays(1);
      setDates(groupedDays(date, 1, healthSystem['time-zone']));
    }
  }, [isTablet, isDesktop, isLgDesktop, isMobile]);

  useEffect(() => {
    setDates(
      groupedDays(
        date
          ? moment(date)
            .tz(healthSystem['time-zone'])
            .format()
          : moment()
            .tz(healthSystem['time-zone'])
            .format(),
        numberOfDays,
        healthSystem['time-zone'],
      ),
    );
  }, [numberOfDays]);



  useEffect(() => {

    let runSetState = true;

    (async () => {
      if (date) {

        const days = 1;
        if (
          (schedule?.forceDeactivation === false ||
            schedule['force-deactivation'] === false) &&
          schedule?.active === true
        )
          setTodaySchedule(
            await fetchTimes(scheduleId, date, days, runSetState),
          );

        else setTodaySchedule([]);

        setLoading(false);
      }
    })();

    // eslint-disable-next-line consistent-return
    return () => {
      runSetState = false;
    };
  }, [
    schedule,
    date,
    isMobile,
    availableScheduleDays
  ]);

  const compare = () => {
    return (
      moment(date)
        .tz(location['time-zone'])
        .startOf('day')
        .format() ===
      moment(todaySchedule?.times?.[0])
        .tz(location['time-zone'])
        .startOf('day')
        .format()
    );
  };

  if (visit.data === null) return <></>;
  if (visit.data === 'loaded') return <PageNotFound />;

  const handleChange = e => {
    setCancelForm({ reason: { value: e.reason.value } });
  };
  let type = '';
  if (healthSystem.region !== null) {
    type = 'region';
  } else {
    type = 'hs';
  }

  const submit = async () => {
    const id = match.params.visitId;
    const params = {
      type: 'visits',
      id,
      attributes: {
        cancel_reason: cancelForm.reason.value,
      },
    };

    const { errors, data } = await cancel(id, params);

    if (!isEmpty(data)) {
      const uri = `/visits/${id}/canceled`;
      return history.push(uri);
    }
    if (!isEmpty(errors)) {
      setFormErrors(errors);
    }

    return false;
  };



  const handleDateChange = date => {
    //setLoading(true);
    setDate(date);
    setDates(groupedDays(date, numberOfDays, healthSystem['time-zone']));
  };

  const handleSetDates = date => {
    if (isMobile) {
      setDates(groupedDays(date, 1, healthSystem['time-zone']));
    }
  };

  const hasTimes = () =>
    todaySchedule.times &&
    todaySchedule.times.length > 0 &&
    todaySchedule.day !== '';

  const fetchTimes = async (scheduleId, date, days, runSetState) => {

    setLoading(true);
    setNextAvailableTime('');
    const dateObj = {
      day: null,
      times: [],
    };
    const today = moment()
      .tz(location['time-zone'] || healthSystem['time-zone'])
      .format();
    const toDate = moment(date)
      .tz(location['time-zone'] || healthSystem['time-zone'])
      .startOf('day')
      .add(1, 'day')
      .format();
    const toDateToCompare = moment(today)
      .tz(location['time-zone'] || healthSystem['time-zone'])
      .add(availableScheduleDays - 1, 'days')
      .format();

    const timesResponse = await getTimes(
      scheduleId,
      appointmentTypeId,
      date,
      days,
      visit?.data?.attributes?.['referring-facility-id'] ? 'discharge' : 'patient',
      toDate,
    );

    if (timesResponse[0]['next-time']) {
      const nextTime = timesResponse[0]['next-time'];
      if (
        moment(nextTime)
          .tz(location['time-zone'] || healthSystem['time-zone'])
          .isSameOrBefore(toDateToCompare)
      )
        setNextAvailableTime(timesResponse[0]['next-time']);
    }
    if (runSetState) {
      const { times } = timesResponse[0];
      const groupedTimes = groupBy(
        times,
        moment(date).tz(
          location['time-zone'] || healthSystem['time-zone'],
        ),
      );
      const datesList = Object.keys(groupedTimes);
      dateObj.day = datesList[0];
      dateObj.times = groupedTimes[datesList[0]] || [];
    }

    return dateObj;
  };

  const onScroll = dateSliderRef => {
    if (dateSliderRef.current.id === whoIsScrolling) {
      setScrollLeft({
        left: dateSliderRef.current.scrollLeft,
        fromComponent: whoIsScrolling,
      });
    }
  };

  const dischargeLink = () => {
    return isDischargeSchedule ? `/${facilityPermalink}/discharge` : '';
  };

  const nextAvailableTimeLink = date => {
    const apptQueryString = QueryString.stringify({
      appointmentTypes: appointmentTypeId,
      date: moment(date)
        .tz(location['time-zone'])
        .format(),
    });
    const apptLink = `${dischargeLink()}/schedule/${scheduleId
      }?${apptQueryString}`;
    return apptLink;
  };



  const cancelReasonOptions = [
    {
      value: "I'm feeling better",
      text: t('VisitCancelConfirmationPage.cancelresponseFeelingBetter'),
    },
    {
      value: "There's too long of a wait",
      text: t('VisitCancelConfirmationPage.cancelresponseToolongForaWait'),
    },
    {
      value: "I'm going somewhere else",
      text: t('VisitCancelConfirmationPage.cancelresponseSomewhereElse'),
    },
    // {
    //   value: 'Cancelling to reschedule',
    //   text: t('VisitCancelConfirmationPage.cancelresponseToReschedule'),
    // },
    {
      value: 'Other',
      text: t('VisitCancelConfirmationPage.cancelresponseOther'),
    },
  ];

  let isDischargeSchedule = visit?.data?.attributes?.['referring-facility-id'] && visit?.data?.attributes?.['referring-facility-name'];
  let facilityPermalink = facility?.permalink;
  let serviceId = Object.values(allServices)?.find(service => service?.id == schedule?.service?.id)?.relationships?.serviceLine?.data?.id;
  const serviceLines = allServiceLines[serviceId]?.relationships?.services?.data?.map(s => s.id);
  const subServices = serviceLines?.map(service => `subservice_ids=${service}`).join('&');
  const serviceAttribute = allServiceLines[serviceId]?.attributes
  const servicePermalink = serviceAttribute?.permalink === "primary-care" ? "find-a-doctor" : serviceAttribute?.permalink;
  const serviceName = serviceAttribute?.name === "Primary Care" ? "Find A Doctor" : serviceAttribute?.name;
  const appointmentTypeId = visit?.data?.attributes?.['appointment-type'] ? visit?.data?.attributes?.['appointment-type'] : '';
  const viewMoreLink = isDischargeSchedule ? `/${facilityPermalink}/discharge?appointmentTypeId=${appointmentTypeId}&service=${serviceId}` : `/search?appointmentTypes=${appointmentTypeId}&insurancePlans=&isVirtual=false&service=${servicePermalink}&serviceName=${serviceName}&service_id=${serviceLines?.join(",")}&specialtyId=&specialtyName=&specialtyType=&${subServices}&timestamp=&ux_mode=default&viewName=list&zip=${location?.zip}`;


  let regLink = appointmentTypeId
    ? `/schedule/${scheduleId
    }?appointmentTypes=${appointmentTypeId}&inactive=${!schedule.active}&type=${type}&service=${servicePermalink}`
    : `/schedule/${scheduleId
    }?inactive=${!schedule.active}&type=${type}&service=${servicePermalink}`;

  if (isEmpty(location) || isNull(location?.name) || isUndefined(location?.name)) return <></>;
  return (
    <main className="VisitCancelPage container">
      <BreadCrumbs onlyHome />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={isMobile ? 12 : 5}>
          <Grid container spacing={1}>
            <Grid item xs={4} sm={5}>
              {setProviderAvatar(
                provider,
                location,
                schedule?.service?.permalink,
                healthSystem,
                schedule?.service && schedule?.service?.name ? schedule?.service?.name : '',
                facility
              )}
            </Grid>
            <Grid
              item
              xs={8}
              sm={isMobile ? 4 : 7}
              className="providerhead"
            >
              {!isEmpty(provider) &&
                !isEmpty(provider?.attributes) &&
                (
                  <>
                    <Badge className="provider-name">
                      {provider?.attributes?.name}
                      {provider?.attributes?.suffix &&
                        `, ${provider?.attributes?.suffix}`}
                    </Badge>
                    {schedule?.service && schedule?.service?.name && (
                      <p className="service-name">{schedule?.service?.name}</p>
                    )}
                  </>
                )}
              <Location location={{ ...location, ...camel(location) }} />
              <Phone phoneNumber={location?.phone} />
              <br />
              {visit?.data?.attributes["appointment-type-name"] &&
                <Grid container spacing={1}>
                  <Grid item xs={5} sm={4}> <Typography variant="body2"> {t('CancelVisitPage_ApptType')}: </Typography></Grid>
                  <Grid item xs={7} sm={8}> {visit?.data?.attributes["appointment-type-name"]}  </Grid>
                </Grid>}
              {visit?.data?.attributes['appointment-at'] &&
                <Grid container spacing={1}>
                  <Grid item xs={5} sm={4}><Typography variant="body2">  {t('CancelVisitPage_ApptTime')}:</Typography> </Grid>
                  <Grid item xs={7} sm={8}> {moment(visit?.data?.attributes['appointment-at']).tz(location["time-zone"]).format('dddd, MMMM Do YYYY h:mm a')} </Grid>
                </Grid>}
            </Grid>
          </Grid>
        </Grid>
        {isMobile ? <hr className={classes.separator} /> : <div className={classes.separatorVertical}></div>}

        <Grid item xs={12} sm={isMobile ? 12 : 6}>
          <Grid container spacing={1} >
            <Grid item xs={11} sm={isMobile ? 6 : 12} className='typography-title'>
              <Typography variant="h6" component="h6" className={classes.rescheduleAppt}>
                {isCancelledVisit ? t('CancelPage_MessageonReCancelingCanceledAppointments') : t('CancelVisitPage_RescheduleanAppointment')}
              </Typography>
            </Grid>
            {isCancelledVisit && <Grid container item className='typography-title'>
              <Trans
                i18nKey="CancelPage_MessageonReCancelingCanceledAppointments_ViewMoreOptions"
                components={[<Link className="ViewMore" to={viewMoreLink} />]}
              />
            </Grid>}
            {!isCancelledVisit &&
              <>
                {isMobile && (
                  <>
                    <Grid
                      className="mb-4 text-center times-container"
                      container
                      alignItems="flex-start"
                      justify="center"
                    >
                      <Grid item xs={8}>
                        <DateCarousel
                          dates={dates}
                          onDateChange={date => {
                            handleDateChange(date)
                            // Reset the Date Time
                            setTodaySchedule({
                              day: null,
                              times: [],
                            });
                          }}
                          serviceName={schedule?.service?.name}
                          timezoneName={
                            location['time-zone'] ||
                            healthSystem['time-zone']
                          }
                          dateHeadingLayout="horizontal"
                          scheduleId={scheduleId}
                        />
                      </Grid>
                      <Grid className="ProviderDate CalendarIconM" item xs={2}>
                        <button
                          aria-label="Calender Icon"
                          className={`view-button ${classes.mapButton}`}
                          type="button"
                          onClick={() => setShowTimePickerDialog(true)}
                        >
                          <DateRangeIcon style={{ marginRight: '5px' }} />
                        </button>
                      </Grid>
                    </Grid>
                    {!(isServiceGroupB_C(servicePermalink)) &&
                      !ER_SERVICE_NAMES.includes(schedule?.service?.name) &&
                      !UC_SERVICE_NAMES.includes(schedule?.service?.name) &&
                      isMobile &&
                      <>

                        {(!hasTimes() || !compare()) &&
                          appointmentTypeId &&
                          nextAvailableTime !== '' && (
                            <Grid item container sm={10} className="dates">
                              <Link
                                to={nextAvailableTimeLink(nextAvailableTime)}
                                className={
                                  schedule?.active
                                    ? 'Appointment Link'
                                    : 'Appointment AppointmentDisabled Link'
                                }
                                style={{ textDecoration: 'none' }}
                              >
                                <button
                                  type="button"
                                  className="btn-default-outline appointment-button"
                                >
                                  {t('nextavailabiltyis')}{' '}
                                  {moment(nextAvailableTime)
                                    .tz(location['time-zone'])
                                    .format('ddd, MMM DD [at] hh:mm A')}
                                </button>
                              </Link>
                            </Grid>
                          )}

                        {!hasTimes() && appointmentTypeId && nextAvailableTime === '' && (
                          <Grid item container sm={10} className="dates">
                            <>
                              <div className="noAvailableAppointments">
                                {loading
                                  ? `${t('ScheduleResultList.hangTight')} ${t(
                                    'ScheduleResultList.fetchingresults',
                                  )}...`
                                  : noAvailableAppointments}
                              </div>
                            </>
                          </Grid>
                        )}


                        {hasTimes() && compare() && (
                          <Grid
                            className="mb-4 text-center times-container"
                            container
                            alignItems="flex-start"
                            justify="center"
                          >
                            <Grid item xs={12}>
                              <div className="time-container">
                                {todaySchedule.times.map(time => (
                                  <div key={time} className="time-container-button">
                                    <Appointment
                                      link={`${dischargeLink()}/schedule/${scheduleId
                                        }?appointmentTypes=${appointmentTypeId}&service=${servicePermalink}`}
                                      location={location}
                                      time={time}
                                      outline
                                      timezoneName={location['time-zone']}
                                      providerName={
                                        provider?.attributes ? provider?.attributes?.name : ''
                                      }
                                      inactive={!schedule?.active}
                                    />
                                  </div>
                                ))}
                              </div>
                            </Grid>
                          </Grid>
                        )}
                        <Grid container justify="space-between">
                          <Grid item>
                            <Link
                              to={`${dischargeLink()}/schedule/calendar/${scheduleId
                                }?appointmentTypes=${appointmentTypeId}&service=${servicePermalink}`}
                            >
                              {t('ProviderLocationMobile.allAvailability')}
                            </Link>
                          </Grid>
                        </Grid>
                      </>}
                  </>

                )}

                <Grid>
                  {showTimePickerDialog && (
                    <DialogMobile
                      content={
                        <PickerDate
                          handleChange={e => {
                            const startDate = moment(e?.start);
                            if (startDate.isValid()) {
                              setDateTemp(e);
                            }
                          }}
                          timezone={
                            location['time-zone'] ||
                            healthSystem['time-zone']
                          }
                          timestamp={moment(dates[0])
                            .tz(
                              location['time-zone'] ||
                              healthSystem['time-zone'],
                            )
                            .format()}
                          scheduleId={scheduleId}
                        />
                      }
                      handleShowDialog={e => {
                        setShowTimePickerDialog(false);
                        if (e) {
                          handleSetDates(dateTemp?.start);
                          handleDateChange(dateTemp?.start);
                        }
                      }}
                      icon={<WatchLater />}
                      showDialog={showTimePickerDialog}
                      title="Date Picker"
                    />
                  )}
                </Grid>
                {!(isServiceGroupB_C(servicePermalink)) &&
                  !ER_SERVICE_NAMES.includes(schedule?.service?.name) &&
                  !UC_SERVICE_NAMES.includes(schedule?.service?.name) &&
                  !isMobile &&
                  (
                    <Grid item xs={11} >
                      <Sticky stickyClassName="sticky test">
                        <DateCarousel
                          dates={dates}
                          onDateChange={handleDateChange}
                          timezoneName={
                            location
                              ? location['time-zone']
                              : healthSystem['time-zone']
                          }
                          dateHeadingLayout="horizontal"
                          onScroll={onScroll}
                          scrollLeft={scrollLeft}
                          whoIsScrolling={whoIsScrolling}
                          setWhoIsScrolling={setWhoIsScrolling}
                        />
                      </Sticky>
                      <FacilityDateCarousel
                        scheduleId={scheduleId}
                        facility={facility?.id}
                        appointmentTypeId={appointmentTypeId}
                        link={regLink}
                        startTime={startTime}
                        endTime={endTime}
                        maxTimesPerDay={4}
                        timezoneName={location ? location['time-zone'] : ''}
                        datesShow={dates}
                        //datesLoading={datesLoading}
                        dateCurrent={date}
                        noOfDays={numberOfDays}
                        uxMode={'default'}
                        scrollLeft={scrollLeft}
                        onScroll={onScroll}
                        setWhoIsScrolling={setWhoIsScrolling}
                        whoIsScrolling={whoIsScrolling}
                        servicePermalink={servicePermalink}
                        nowContext={false}
                        isDischarge={isDischargeSchedule ? true : false}
                      />
                      <Grid item xs={10} sm={11} className={classes.fullSchedule}>
                        <FullSchedule
                          schedule={scheduleId}
                          appointmentType={appointmentTypeId}
                          inactive={!schedule.active}
                          type={type}
                          servicePermalink={
                            servicePermalink
                          }
                          nowContext={false}
                          facilityPermalink={isDischargeSchedule ? facilityPermalink : ''}
                        />
                      </Grid>
                    </Grid>
                  )}
                {
                  (isServiceGroupB_C(servicePermalink) ||
                    ER_SERVICE_NAMES.includes(schedule?.service?.name) ||
                    UC_SERVICE_NAMES.includes(schedule?.service?.name)) &&
                  (
                    <Grid item xs={11} className='typography-title'>
                      <AvailableTimesToday
                        appointmentTypeId={appointmentTypeId}
                        context={isDischargeSchedule ? "discharge" : "patient"}
                        link={regLink}
                        scheduleId={visit?.included.find(l => l.type === 'schedules')?.id}
                        facility={facility?.id}
                        timezoneName={location ? location['time-zone'] : healthSystem['time-zone']}
                        providerName={!isEmpty(provider) ? provider?.attributes?.name : location?.name}
                        startTimestamp={date}
                        serviceName={schedule?.service?.name}
                        isMobile={isMobile}
                        setHideFullSchedule={setHideFullSchedule}
                        venueType={"physician_services"}
                        servicePermalink={servicePermalink}
                      />

                      {isMobile ?
                        <Grid item>
                          <Link
                            to={`${dischargeLink()}/schedule/calendar/${scheduleId
                              }?appointmentTypes=${appointmentTypeId}&service=${servicePermalink}`}
                          >
                            {t('ProviderLocationMobile.allAvailability')}
                          </Link>
                        </Grid> :

                        <FullSchedule
                          schedule={scheduleId}
                          appointmentType={appointmentTypeId}
                          inactive={!schedule.active}
                          type={type}
                          servicePermalink={servicePermalink}
                          nowContext={false}
                          facilityPermalink={isDischargeSchedule ? facilityPermalink : ''}
                        />}
                    </Grid>
                  )}
                <div>
                  <Grid container item className='typography-title'>
                    <Trans
                      i18nKey="CancelVisitsPage_ViewMoreOptions"
                      components={[<Link className="ViewMore" to={viewMoreLink} />]}
                    />
                  </Grid>

                  <hr className={`${classes.separator} typography-title`} style={{ display: isReschedulePage ? 'none' : 'inline-block' }} />
                  <Grid container item xs={7} sm={10} style={{ display: isReschedulePage ? 'none' : 'inline-block' }} className='typography-title' justify={isMobile ? 'center' : 'flex-start'}>
                    <Typography variant="h6" component="h6" className={classes.cancelAppt}>
                      {t('VisitCancelConfirmationPage.cancelresponsePleaseCancel')}
                    </Typography>
                  </Grid>
                  <Grid container className={isMobile ? "" : 'typography-title'}>
                    <Grid style={{ display: isReschedulePage ? 'none' : 'inline', pointerEvents: pastDate || isCancelledVisit ? 'none' : 'auto' }} item xs={9} sm={9} >
                      <CustomSelect
                        fieldKey="reason"
                        fieldValue={cancelForm.reason}
                        label={t('VisitCancelConfirmationPage.cancelresponseLetUsKnow')}
                        items={cancelReasonOptions}
                        validateFormHandler={e => handleChange(e, 'reason')}
                        hasError={!isEmpty(formErrors)}
                        errorMessage={
                          !isEmpty(formErrors)
                            ? formErrors.map(e => e.detail).join(', ')
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={1} style={{ display: isReschedulePage ? 'none' : 'inline' }}>
                      <Button
                        className={classes.button}
                        disabled={pastDate || isCancelledVisit}
                        type="button"
                        onClick={() => submit()}
                      >
                        {t('VisitCancelPage.yesCancelMyVisit')}
                      </Button>

                    </Grid>
                  </Grid>
                </div>
                {paymentInfo && paymentInfo.payment && paymentInfo.payment.status && (
                  <Grid item xs={12} sm={8} style={{ display: isReschedulePage ? 'none' : 'inline' }}>
                    {paymentInfo.fee.cancellationChargeAmount === 0 ? (
                      <Typography variant="body1" component="p">
                        You will be refunded back{' '}
                        {getCurrency(paymentInfo.fee.currency)}
                        {Number(paymentInfo.payment.amount).toFixed(2)} for canceling
                        the appointment
                      </Typography>
                    ) : (
                      <Typography variant="body1" component="p">
                        Cancellation fee in the amount{' '}
                        {getCurrency(paymentInfo.fee.currency)}
                        {Number(paymentInfo.fee.cancellationChargeAmount).toFixed(
                          2,
                        )}{' '}
                        will be charged and you will be refunded back{' '}
                        {getCurrency(paymentInfo.fee.currency)}
                        {Number(
                          paymentInfo.payment.amount -
                          paymentInfo.fee.cancellationChargeAmount,
                        ).toFixed(2)}{' '}
                        for canceling the appointment
                      </Typography>
                    )}
                  </Grid>
                )}

                <Typography variant="body1" component="p" className={`${classes.errorWrapper} typography-title`} style={{ display: (pastDate) && !isReschedulePage ? 'inline' : 'none' }}>
                  {pastDate ? t('CancelPage_MessageonCancellingPastAppointments') : ""}
                </Typography>
              </>}
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
};

VisitCancelPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      visitId: PropTypes.string,
    }),
  }).isRequired,
};



export default VisitCancelPage;
